
import './App.css';
import LoginPage from './components/auth/LoginPage';
import HomePage from './components/home_component/HomePage';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PrivateRoutes from './utils/PrivateRoutes'
import { useState } from 'react';
import AddLatLong from './components/add_lat_long/AddLatLong';
import AddOfficeTimePage from './components/add_office_time/AddOfficeTimePage';





function App() {

  const [auth, setAuth] = useState({ token: false });

  // Callback function to update auth.token
  const setToken = (value) => {
    setAuth({ token: value });
  };

  return (
   
    
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage setToken={setToken}/>}/>
       
        <Route element={<PrivateRoutes auth={auth} />}>
        <Route  path="/home" element={<HomePage/>}/>
        <Route  path="/add-fence" element={<AddLatLong/>}/>
        <Route  path="/add-time" element={<AddOfficeTimePage/>}/>
            </Route>
      
      </Routes>
    </Router>
    
   
  );
}

export default App;
