import React,{useState,useRef,useEffect} from 'react'
import { gray500,gray100,red500,gray300,gray400,green100,green600,gray700, gray600, gray200 } from '../../utils/colors';
import getCompanyList from '../../services/CompanyListService';
import Loading from '../loader/Loading';
import getModuleList from '../../services/ModuleListService';
import getPlantList from '../../services/PlantListService';
import getApprovalTemplates from '../../services/ApprovalTemplateService';
import createApprovalTemplate from '../../services/CreateApprovalTemplatService';
import getStageDetails from '../../services/StageDetailsService';
import getAllLeaveType from '../../services/AllLeaveTypeService';
import getUserList from '../../services/UsersService';
import addStageDetails from '../../services/AddStageService';
import getAuthorization from '../../services/AuthorizationService';
import deleteStageDetails from '../../services/DeleteStageService';
import deleteTemplate from '../../services/DeleteTemplateService';
import getStageUsers from '../../services/stageUserService';
import addStageUser from '../../services/AddStageUserService';
import deleteStageUser from '../../services/DeleteStageUserService';
import addTerm from '../../services/AddTermService';
import getTermList from '../../services/TermListService';
import deleteTermService from '../../services/DeleteTermService';
import getIouList from '../../services/IouTypeList';
import getMoveOrderList from '../../services/MoveOrderTypeService';
import getLoanTypeList from '../../services/LoanTypeService';



const companyUrl="http://118.179.60.93:8085/ords/wepl/hrlm/getCompanyList";
const moduleUrl="http://118.179.60.93:8085/ords/wepl/hrlm/approval_module_types";
const plantUrl="http://118.179.60.93:8085/ords/wepl/hrlm/get_plant_details";
const approvalTemplateUrl="http://118.179.60.93:8085/ords/wepl/hrlm/approval_templates";
const createApprovalTemplateUrl="http://118.179.60.93:8085/ords/wepl/hrlm/create_approval_template";
const stageDetailsUrl="http://118.179.60.93:8085/ords/wepl/hrlm/stage_details_of_template";
const allLeaveTypeUrl="http://118.179.60.93:8085/ords/wepl/hrlm/leave_type_list_web";
const userUrl="http://118.179.60.93:8085/ords/wepl/hrlm/employee_search_by_name_id";
const addStageUrl="http://118.179.60.93:8085/ords/wepl/hrlm/insert_stage_approver_emp";
const authorizationUrl="http://118.179.60.93:8085/ords/wepl/hrlm/authorization_types_web";
const deleteStageUrl="http://118.179.60.93:8085/ords/wepl/hrlm/delete_approval_emp_web";
const deleteTemplateUrl="http://118.179.60.93:8085/ords/wepl/hrlm/delete_approval_template_web";
const stageEmpUrl="http://118.179.60.93:8085/ords/wepl/hrlm/approval_stage_emp_list";
const addStageUserurl="http://118.179.60.93:8085/ords/wepl/hrlm/insert_stage_users_web";
const deleteStageUserUrl="http://118.179.60.93:8085/ords/wepl/hrlm/delete_stage_users_web";
const addTermUrl="http://118.179.60.93:8085/ords/wepl/hrlm/create_stage_term_web";
const termListUrl="http://118.179.60.93:8085/ords/wepl/hrlm/approval_stage_terms_list_web";
const deleteTermUrl="http://118.179.60.93:8085/ords/wepl/hrlm/delete_stage_term_web";
const iouTypeUrl="http://118.179.60.93:8085/ords/wepl/hrlm/iou_types";
const moveOrderTypeUrl="http://118.179.60.93:8085/ords/wepl/hrlm/move_order_type";
const loanTypeUrl="http://118.179.60.93:8085/ords/wepl/hrlm/loan_types";


const psgList=[{"name":"Head Office","id":90},{"name":"Outside Head Office","id":95}];
const leaveRatioList=[{"name":"Equal","value":"=="},{"name":"Not Equal","value":"!="},{"name":"Less Than","value":"<"},{"name":"Greater Than","value":">"},{"name":"Less or Equal","value":"<="},{"name":"Greater or Equal","value":">="}];
const iouRatioList=[{"name":"Equal","value":"=="},{"name":"Max","value":">"},{"name":"Min","value":"<"},{"name":"Less or Equal","value":"<="},{"name":"Greater or Equal","value":">="}];
const moveOrderRatioList=[{"name":"Equal","value":"=="},{"name":"Max","value":">"},{"name":"Min","value":"<"},{"name":"Less or Equal","value":"<="},{"name":"Greater or Equal","value":">="}];
const requisitionRatioList=[{"name":"Equal","value":"=="},{"name":"Max","value":">"},{"name":"Min","value":"<"},{"name":"Less or Equal","value":"<="},{"name":"Greater or Equal","value":">="}];
const loanRatioList=[{"name":"Equal","value":"=="},{"name":"Max","value":">"},{"name":"Min","value":"<"},{"name":"Less or Equal","value":"<="},{"name":"Greater or Equal","value":">="}];

export default function ApprovalSetupPage() {

    let selectedCompanyCode,selectedPalntCode,selectedModuleCode,selectedUserId,selectedAuthorizerId,selectPsgid,selectDocumentIdGolbal;
    let selectedRatioValue;
    let authName;
    const approvalTemplateRef = useRef(null);
    const usersRef=useRef(null);
    const stageSequenceRef=useRef(null);
    const stageStatusRef=useRef(null);
    const userStatusRef=useRef(null);
    const termValueRef=useRef(null);
    const termStatusRef=useRef(null);
    const [createLoading,setCreateLoading]=useState(true);
    const [company,setCompany]=useState([]);
    const [module,setModule]=useState([]);
    const [plant,setPlant]=useState([]);
    const [isPlantLoading,setIsPlantLoading]=useState(true);
    const [templateName,setTemplateName]=useState('');
    const [companyCode,setCompanyCode]=useState('');
    const [plantCode,setPlantCode]=useState('');
    const [moduleCode,setModuleCode]=useState('');
    const [selectedCompanyname,setSelectedCompanyName]=useState('');
    const [selectedPlantname,setSelectedPlantName]=useState('');
    const [selectedModulename,setSelectedModuleName]=useState('');
    const [template,setTemplate]=useState([]);
    const [isTemplateLoading,setIsTemplateLoading]=useState(true);
    const [buttonState,setButtonState]=useState('stages');
    const [selectedTemplate,setSelectedTemplate]=useState({});
    const [stageDetails,setStageDetails]=useState([]);
    const [isStageLoading,setIsStageLoading]=useState(true);
    const [leaveTypes,setLeaveType]=useState([]);
    const [isLeaveTypeLoading,setIsLeaveTypeLoading]=useState(true);
    const [users,setUsers]=useState([]);
    const [isUsersLoading,setIsUsersLoading]=useState(true);
    const [selectedUser,setSelectedUser]=useState({});
    const [searchKey,setSearchKey]=useState({});
    let id,username;
    const [userId,setUserId]=useState('');
    const [selectedUserName,setSelectedUserName]=useState('');
    const [sategSequenceCnt,setStageSequenceCnt]=useState('');
    const [sateStatusCnt,setStageStatusCnt]=useState('');
    const [authorizers,setAuthorizers]=useState([]);
    const [authorizerId,setAuthorizerId]=useState('');
    const [authorizerName,setAuthorizerName]=useState('');
    const [psgId,setPsgId]=useState('');
    const [psgName,setPsgName]=useState('');
    const [userStatusCnt,setUserStatusCnt]=useState('');
    const [stageUsers,setStageUsers]=useState([]);
    const [isStageUserLoading,setIsStageUserLoading]=useState(true);
    const [termValue,setTermValue]=useState('');
    const [termStatus,setTermStatus]=useState('');
    const [selectedTermId,setDocumnetId]=useState('');
    const [selectedDocumnetName,setSelectedLeaveName]=useState('');
    const [selectedTermName,setLeaveTerm]=useState('');
    const [seletedRationName,setSelectedRatioName]=useState('');
    const [ratioValue, setRatioValue]=useState('');
    const [termList,setTermList]=useState([]);
    const [isTermListLoading,setIsTermListLoading]=useState(true);
    const [createTemplateError,setCreateTemplateError]=useState({});
    const [isTmplateErorr,setIsTemplateErorr]=useState(true);
    
    const [openCloseCreate,setOpenCloseCreate]=useState(false);
    const [stageModal,setStageModal]=useState(false);

    const [addStageError,setAddStageError]=useState({});

    const [userModal,setUserModal]=useState(false);

    const [addUserError,setAddUserError]=useState({});
    const [termModal,setTermModal]=useState(false);
    const [addTermError,setAddTermError]=useState({});
    const [iouTypes,setIouTypes]=useState([]);
    const [isIouTypeLoading,setIsIouTypeLoading]=useState(true);
    const [moveOrderTypes, setMoveOrderTypes]=useState([]);
    const [isMoveOrderTypeLoading,setIsMoveOrderTypeLoading]=useState(true);

    const [lonTypes,setLoanTypes]=useState([]);

    const [isLoanTypeLoading,setIsloanTypeLoading]=useState(true);
    const [showAlertCreateTemplate, setShowAlertCreateTemplate] = useState(false);
    const [showErrorAlertCreateTemplate, setShowErrorAlertCreateTemplate] = useState(false);
    const [uId,setUid]=useState('');
    useEffect(()=>{
      const id=localStorage.getItem('userId');
      setUid(id);
      
      
      },[])
   
    const getLoanType=async()=>{
      const loan=await getLoanTypeList(loanTypeUrl);

      setLoanTypes(loan.loan_type);
      setIsIouTypeLoading(false);
    }

    const getMoveOrderType=async(id)=>{
      const mv=await getMoveOrderList(moveOrderTypeUrl,id);

      setMoveOrderTypes(mv.move_order_type);
      setIsMoveOrderTypeLoading(false);
    }
    const getIou=async(id)=>{
      const iou=await getIouList(iouTypeUrl,id);
      setIouTypes(iou.iou_type);
      setIsIouTypeLoading(false);
    }

    const validateAddterm=()=>{
      const addTermError={};
      if(selectedDocumnetName===''){
        addTermError.selectedLeaveName="Select Document";
      }
      if(selectedTermName===''){
        addTermError.leaveTerm="Select Term";
      }
      if(seletedRationName===''){
        addTermError.seletedRationName="Select a ratio";
      }
      if(!termValue.trim()){
        addTermError.termValue="Please Enter value";
      }
      if(!termStatus.trim()){
        addTermError.termStatus="Please Enter Status";
      }
      setAddTermError(addTermError);

      return Object.keys(addTermError).length===0;
    }

    const validateAddUser=()=>{
      const addUserError={};
      if(selectedUserName===''){
        addUserError.selectedUserName="Please Select User";
      }
      if(!userStatusCnt.trim()){
        addUserError.userStatusCnt="Please Enter Status";
      }
      setAddUserError(addUserError);

      return Object.keys(addUserError).length===0;

    }

    const validateAddStage=()=>{
      const addStageError={};
      if(!sategSequenceCnt ===''){
        addStageError.sategSequenceCnt="Please Enter Sequence";
      }
      if(!sateStatusCnt.trim()){
        addStageError.sateStatusCnt="Please Enter Status";
      }
      if(selectedUserName===''){
        addStageError.selectedUserName="Please Select User";
      }
      if(authorizerName===''){
        addStageError.authorizerName="Please Select Authorizer Type";
      }

      setAddStageError(addStageError);

      return Object.keys(addStageError).length ===0;

    }


    const validateCreateTemplate=()=>{
      const createTemplateError = {};

      if(!templateName.trim()){
        createTemplateError.templateName="Template Name is Required";
      }
      if(selectedCompanyname===''){
        createTemplateError.selectedCompanyname="Company is Required";
      }
      if(selectedPlantname===''){
        createTemplateError.selectedPlantname="Plant is Required";
      }
      if(selectedPlantname===''){
        createTemplateError.selectedPlantname="Plant is Required";
      }
      if(selectedModulename===''){
        createTemplateError.selectedModulename="Module is Required";
      }
      if(psgName===''){
        createTemplateError.psgName="Rule is Required";
      }

      setCreateTemplateError(createTemplateError);

      return Object.keys(createTemplateError).length === 0;

    }
    
    const deleteTerm=async(atId)=>{
      const del=await deleteTermService(deleteTermUrl,atId);
      setIsTermListLoading(true);
      setTermList([]);
      getTerm(selectedTemplate.AM_ID,uId);
    }

    const getTerm=async(amId,uId)=>{
        const term=await getTermList(termListUrl,amId,uId);

        setTermList(term.TERMS_DETAIL_LIST);
        setIsTermListLoading(false);

    }

    const insertTerm=async()=>{
      if(validateAddterm()){
        const ins=await addTerm(addTermUrl,selectedTemplate.AM_ID,selectedTermName,ratioValue,termValue,termStatus,selectedTermId);
      setTermModal(false);
        setIsTermListLoading(true);
      setTermList([]);
      getTerm(selectedTemplate.AM_ID,uId);
      }
      else{
        setTermModal(true);
      }
    }
    const onAddTermCancel=()=>{
      setTermValue('');
      termValueRef.current.value="";
      setTermStatus('');
      termStatusRef.current.value="";
      setSelectedLeaveName('');
      setDocumnetId('');
      setLeaveTerm('');
      setSelectedRatioName('');
      setRatioValue('');
    }

    const delStageUser=async(aoId)=>{
        const delU=await deleteStageUser(deleteStageUserUrl,aoId);
        setIsStageUserLoading(true);
      setStageUsers([]);
      stageUser(selectedTemplate.AM_ID);
    }

    const insertStageUser=async()=>{
     if(validateAddUser()){
      const adu=await addStageUser(addStageUserurl,userId,selectedTemplate.AM_ID,userStatusCnt);
     setUserModal(false);
      setIsStageUserLoading(true);
      setStageUsers([]);
      stageUser(selectedTemplate.AM_ID);
     }
     else{
      setUserModal(true);
     }
    }


    const stageUser=async(amId)=>{
      const stu=await getStageUsers(stageEmpUrl,amId);
      setStageUsers(stu.USERES_LIST);
      setIsStageUserLoading(false);
    }


    const deleteTem=async(templateId)=>{
      const dt=await deleteTemplate(deleteTemplateUrl,templateId);
      setIsTemplateLoading(true);
      setTemplate([]);
      getTemplate();

    }

    const deleteStage=async(stId)=>{
      const deleteStage=await deleteStageDetails(deleteStageUrl,stId);
      setIsStageLoading(true);
      setStageDetails([]);
      callStageDetails(selectedTemplate.AM_ID);

    }

    const [informerError,setInformerError]=useState(false);

    const addStage=async()=>{
      if(validateAddStage()){
        console.log(authorizerId);
       if(authorizerId==='13'){
          if(stageDetails.length===0){
            const addStage=await addStageDetails(addStageUrl,userId,sategSequenceCnt,sateStatusCnt,selectedTemplate.MODULE_TYPE,authorizerId,selectedTemplate.AM_ID);
        setStageModal(false);
          setIsStageLoading(true);
        setStageDetails([]);
        callStageDetails(selectedTemplate.AM_ID);
        setInformerError(false);
          }
          else{
            setStageModal(true);
            setInformerError(true);
          }
       }else{
        const addStage=await addStageDetails(addStageUrl,userId,sategSequenceCnt,sateStatusCnt,selectedTemplate.MODULE_TYPE,authorizerId,selectedTemplate.AM_ID);
        setStageModal(false);
          setIsStageLoading(true);
        setStageDetails([]);
        callStageDetails(selectedTemplate.AM_ID);
        setInformerError(false);
       }
      }
      else{
        setStageModal(true);
      }
    }

    const getAuthorizers=async()=>{
      const authorizers=await getAuthorization(authorizationUrl);
      setAuthorizers(authorizers.AUTHORIZATION_TYPES_WEB);
    }



    const onCancelStageAddUpdate=()=>{
      setStageSequenceCnt('');
      //  stageSequenceRef.current.value='';
        setStageStatusCnt(''); 
        stageStatusRef.current.value=''; 
        setSelectedUser({}); 
        setSelectedUserName('');
        setAuthorizerId('');
        setAuthorizerName('');
    }
    const getUsers=async()=>{
      if(!isNaN(searchKey)){
        id=searchKey;
    }
    else{
        username=searchKey;
    }
      const users=await getUserList(userUrl,id,username);
      setUsers(users.EMPLOYEE_LIST);
      setIsUsersLoading(false);
      setSearchKey('');
      usersRef.current.value='';
    }

    const getLeaveType=async()=>{
      const leave=await getAllLeaveType(allLeaveTypeUrl);

      setLeaveType(leave.LEAVE_TYPES_WEB);
      setIsLeaveTypeLoading(false);
    }
    const callStageDetails=async(amId)=>{
      const details= await getStageDetails(stageDetailsUrl,amId);
      const sorted=details.STAGE_DETAILS.sort((a,b)=>a.STAGE_SEQ - b.STAGE_SEQ);
      setStageDetails(sorted);
      setStageSequenceCnt(sorted.length+1);
      getAuthorizers();
      setIsStageLoading(false);
    }

    

    const createTemplate=async()=>{
      if(validateCreateTemplate()){
      
       
        setIsTemplateErorr(false);
        setOpenCloseCreate(false);
      console.log(`validate: ${isTmplateErorr}`);
        const res=await createApprovalTemplate(createApprovalTemplateUrl,templateName,companyCode,plantCode,moduleCode,psgId);
      if(res.AM_ID != null){
        getTemplate();
        setShowAlertCreateTemplate(true);

      }else{
        getTemplate();
        setShowErrorAlertCreateTemplate(true);
      }
     
    //  isCreateTem=false;
      
      }
      else{
        console.log("validation false");
        console.log(isTmplateErorr);
        setOpenCloseCreate(true);
      }
    }

    const getTemplate=async()=>{
      const tem=await getApprovalTemplates(approvalTemplateUrl);
      setTemplate(tem.TEMPLATE_DETAILS);
      setIsTemplateLoading(false);
      setIsTemplateErorr(true);
     
    }

    const getCompany=async()=>{
        const result= await getCompanyList(companyUrl);
        setCompany(result.items);
        
    }

    const getModule=async()=>{
        const data=await getModuleList(moduleUrl);
        setModule(data.MODULE_DETAILS);
    }

  const  getPlant=async(code)=>{

    const plant=await getPlantList(plantUrl,code);

    setPlant(plant.PLANT_DETAILS);
    setIsPlantLoading(false);

    }

   const getCreateModal=()=>{
    getCompany();
    getModule();
    setCreateLoading(false);
    

    }
   
  return (
    <div className=' w-full h-screen flex justify-center items-center '>
        {/* The button to open modal */}
<label onClick={getTemplate} htmlFor="my-modal-3" className="btn bg-green-600 border-none">Approval Template</label>
{/* Put this part before </body> tag */}
<input type="checkbox" id="my-modal-3" className="modal-toggle" />
<div className="modal ">
  <div className="modal-box relative w-11/12 max-w-7xl h-screen">
    <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="text-lg font-bold">Approval Template</h3>
    <div className=' w-full h-[0.3px] bg-gray-200 my-2'></div>
    <div className=' flex flex-row w-full h-10 justify-end  items-end p-1'>
    {/* <div className=' flex flex-row space-x-2'>
                    
                    
                      <input   type="text"  placeholder='id or name'  className='  border  border-gray-300 h-8 w-40 rounded-lg active:border active:border-blue-300 px-3 focus:outline-none' />
   <button  type='submit'>
   <div className=' h-8 w-10 rounded-sm shadow-sm justify-center  bg-gray-100 px-2'>
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8 text-gray-500 font-semibold">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
  </svg>
   </div>
   </button>
  
                  </div> */}
        {/* <button >
            <div htmlFor="my-modal-4" className='btn w-auto h-8 flex justify-center items-center text-sm font-semibold bg-green-600 rounded-md px-2 text-white'>
                        CREATE
            </div>
        </button> */}
       {/* create modal */}
       <label onClick={()=>{setOpenCloseCreate(true);getCreateModal()}} htmlFor="my-modal-4" className={` w-auto h-8 flex justify-center items-center text-xs font-bold bg-green-600 rounded-md px-4 text-white hover:bg-gray-700`}>CREATE</label>


    </div>
    <div className=' w-full h-[0.3px] bg-gray-200 my-2'></div>
    
          

          {
            isTemplateLoading?
            <Loading/>
            :
            <div className="overflow-x-auto">
            <table className="table w-full">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>TEMPLATE NAME</th>
                  <th>COMPANY NAME</th>
                  <th>PLANT NAME</th>
                  <th>FUNCTION NAME</th>
                  <th>WS Rule</th>
                </tr>
              </thead>
              <tbody>
               
                {
                  template.map((e,index)=>
                  <tr key={index}>
                  <th>
                  <label onClick={()=>{setSelectedTemplate(e);callStageDetails(e.AM_ID)}} htmlFor="my-modal-5" className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-amber-400 ">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
          </svg>
                  </label>
                 
                  {/* <div className="modal-action h-10">
                    <label htmlFor="my-modal-5" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-amber-400 ">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
          </svg>
                    </label>
                  </div> */}
                  </th>
                  <td>{e.APPROVAL_STAGE_NAME}</td>
                  <td>{e.COMPANY_NAME}</td>
                  <td>{e.PLANT_NAME}</td>
                  <td>{e.MODULE_TYPE_NAME}</td>
                  <td>{e.PSG===90? "Head Office":"Outside Head Office"}</td>
                </tr>
                  
                  )
                }
                
                
              </tbody>
            </table>
          </div>
          }

        

  </div>
</div>

  {/* create modal */}
{/* Put this part before </body> tag */}
<input type="checkbox" id="my-modal-4" className="modal-toggle" checked={openCloseCreate}  />
<label htmlFor="my-modal-4" className="modal cursor-pointer">
  <label className="modal-box relative h-[460px]" htmlFor="">
    <h3 className="text-lg font-bold mb-2">Add Approval Template</h3>

{
    createLoading? <Loading/>
    :
    <div>
            <input onChange={(e)=>{
               setTemplateName( e.target.value)
            }} ref={approvalTemplateRef} type="text" placeholder="Name" className="input input-bordered w-full mb-2 focus:outline-none" />
             {createTemplateError.templateName && <span className={`text-${red500}`}>{createTemplateError.templateName}</span>}
    
    {/* <select className="select select-bordered w-full focus:outline-none mb-2">
  <option disabled selected>Select Company?</option>
  {
    company.map((e,index)=>
    <option onChange={()=> {selectedCompanyCode=e.company_code; console.log(selectedCompanyCode); setCompanyCode(selectedCompanyCode); getPlant(selectedCompanyCode)}} key={index}>{e.company_name}</option>
    )
  }
 
  
</select> */}
{
  
  <select placeholder='Select Company' value={selectedCompanyname} onChange={(e)=> {selectedCompanyCode=e.target.value; console.log(selectedCompanyCode); setCompanyCode(selectedCompanyCode); setSelectedCompanyName(e.company_name);getPlant(selectedCompanyCode)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Company</option>
  {
    company.map((e,index)=>
    <option  key={index} value={e.company_code}>{e.company_name}</option>
    )
  }
 
  
</select>

  
}
{createTemplateError.selectedCompanyname && <span className={`text-${red500}`}>{createTemplateError.selectedCompanyname}</span>}
    {
        <select value={selectedPlantname} onChange={(e)=>{selectedPalntCode=e.target.value; setPlantCode(selectedPalntCode); setSelectedPlantName(e.PLANT_NAME)}} className="select select-bordered w-full focus:outline-none mb-2">
        <option value="" disabled selected>Select Plant</option>
       {
         isPlantLoading? 
         <p>loading.....</p>
         :plant.map((e,index)=>
          <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}</option>
          )
       }
       
      </select>
    }
    {createTemplateError.selectedPlantname && <span className={`text-${red500}`}>{createTemplateError.selectedPlantname}</span>}
    {
      <select value={selectedModulename} onChange={(e)=>{selectedModuleCode=e.target.value; setModuleCode(selectedModuleCode); setSelectedModuleName(e.MODULE_TYPE_NAME)}} className="select select-bordered w-full focus:outline-none mb-2">
      <option value="" disabled selected>Select Function</option>
      {
        module.map((e,index)=>
        <option value={e.MODULE_ID} key={index}>{e.MODULE_TYPE_NAME}</option>
        )
      }
    </select>
    }
    {createTemplateError.selectedModulename && <span className={`text-${red500}`}>{createTemplateError.selectedModulename}</span>}
    {
      <select value={psgName} onChange={(e)=>{selectPsgid=e.target.value; setPsgId(selectPsgid); setPsgName(e.name)}} className="select select-bordered w-full focus:outline-none mb-2">
      <option value="" disabled selected>Select WS Rule</option>
      {
        psgList.map((e,index)=>
        <option value={e.id} key={index}>{e.name}</option>
        )
      }
    </select>
    }
    {createTemplateError.psgName && <span className={`text-${red500}`}>{createTemplateError.psgName}</span>}



        <div htmlFor="my-modal-4" className=' flex flex-row justify-between '>
            {/* <button className='modal-action'>
                <div className={`bg-${red500} w-auto px-2 py-1 text-white text-sm `}>
                   <p > CANCEL</p>
                </div>
            </button> */}

<div className="modal-action">
      <label onClick={()=>{setOpenCloseCreate(false);setCompanyCode(''); setSelectedCompanyName(''); setPlantCode('');setSelectedPlantName(''); setModuleCode(''); setSelectedModuleName('');setTemplateName('') ;approvalTemplateRef.current.value=""; setPsgId('');setPsgName('') }}  className={` bg-${red500} border-none h-10 w-auto flex justify-center items-center shadow-sm rounded-md px-4 text-white font-semibold text-sm hover:bg-${gray700}`}>CANCEL</label>
    </div>
    <div className="modal-action">
      <label onClick={()=>{ createTemplate(); setCompanyCode(''); setSelectedCompanyName(''); setPlantCode('');setSelectedPlantName(''); setModuleCode(''); setSelectedModuleName('');setTemplateName('') ;approvalTemplateRef.current.value=""; setPsgId('');setPsgName('')}}  className={` bg-${green600} border-none h-10 w-auto flex justify-center items-center shadow-sm rounded-md px-4 text-white font-semibold text-sm hover:bg-${gray700}`}>CREATE</label>
    </div>
        </div>
    </div>
    
}
  </label>
</label>

       {/* end create modal */}


       {/* start edit approval template */}

       <input type="checkbox" id="my-modal-5" className="modal-toggle" />
<div className="modal">
  <div className="modal-box w-11/12 max-w-7xl h-screen">
  <label htmlFor="my-modal-5" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Approval Template</h3>
    <div className={` w-full h-[0.3px] bg-${gray300} my-2`}>

    </div>

    <div className="overflow-x-auto ">
  <table className="table w-full mt-1 mb-10">
    {/* head */}
    <thead>
      <tr>
        <th></th>
        <th>TEMPLATE NAME</th>
        <th>COMPANY NAME</th>
        <th>PLANT NAME</th>
        <th>FUNCTION NAME</th>
        <th>WS Rule</th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
      <tr>
       <th>
       <label onClick={()=>{deleteTem(selectedTemplate.AM_ID)}} htmlFor="my-modal-5" className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
                  </label>
       </th>
        <td>{selectedTemplate.APPROVAL_STAGE_NAME}</td>
        <td>{selectedTemplate.COMPANY_NAME}</td>
        <td>{selectedTemplate.PLANT_NAME}</td>
        <td>{selectedTemplate.MODULE_TYPE_NAME}</td>
        <td>{selectedTemplate.PSG===90? "Head Office":"Outside Head Office"}</td>
      </tr>
      
    </tbody>
  </table>
</div>

<div className=' flex flex-row space-x-4 p-1 border-[0.1px] border-gray-200 rounded-md  w-[282px] px-1 mb-6'>
    <button onClick={()=>{setButtonState('stages')}} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState==='stages'?'bg-green-100':'bg-white'} hover:bg-green-200 ${buttonState==='stages'?`text-${green600}`:`text-${gray500}`} font-medium text-sm`}>
      STAGES
    </button>
    <button onClick={()=>{setButtonState('users');stageUser(selectedTemplate.AM_ID)}} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState==='users'?'bg-green-100':'bg-white'} hover:bg-green-200 ${buttonState==='users'?`text-${green600}`:`text-${gray500}`} font-medium text-sm`}>
      USERS
    </button>
   
 
 <button 
 disabled={selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?true:false}
 onClick={()=>{setButtonState('terms');
      if(selectedTemplate.MODULE_TYPE_NAME === "LEAVE"){
        getTerm(selectedTemplate.AM_ID,uId);
       getLeaveType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="IOU"){
        getTerm(selectedTemplate.AM_ID,uId);
        getIou(uId);
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="MOVE ORDER"){
        getTerm(selectedTemplate.AM_ID,uId);
        getMoveOrderType(uId);
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="LOAN"){
        getTerm(selectedTemplate.AM_ID,uId);
       getLoanType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="EMPLOYEE REQUISITION"){
        getTerm(selectedTemplate.AM_ID,uId);
      //  getLoanType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="TRANSPORT MOVE ORDER"){
        getTerm(selectedTemplate.AM_ID,uId);
        getMoveOrderType(uId);
      }
  }} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState==='terms'?'bg-green-100':'bg-white'} hover:bg-green-200 ${buttonState==='terms'?`text-${green600}`:`text-${gray500}`} font-medium text-sm`}>
      TERMS
    </button>
    
    
    
</div>

{(() => {
        switch (buttonState) {
          case 'stages':
            return <div className=' w-full flex flex-col rounded-md border-[0.2px] p-2 border-gray-300'> 
            <div className=' flex flex-row justify-between'>
              <h3 className={` text-${gray700} font-semibold text-sm`}>Define stages first to final sequentially.</h3>
              <p className='mr-12'>
                <label onClick={()=>setStageModal(true)} htmlFor="my-modal" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                  </label></p>
            </div>
            <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

            </div>

            {
              isStageLoading?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
              </div>
              : 
              stageDetails.length ===0?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>ADD STAGE</h4>
              </div>
              :
              <div className="overflow-x-auto ">
              <table className="table w-full mt-1 mb-10">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Sequence</th>
                    <th>Approver Name</th>
                    <th>authorization type</th>
                    <th>Status</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {/* row 1 */}
                  {
                  stageDetails.map((e,index)=>
                    <tr key={index}>
                  <th>
                   <label onClick={()=>{deleteStage(e.ST_ID)}}  className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>

                              </label>
                   </th>
                    <td>{e.STAGE_SEQ}</td>
                    <td>{e.EMPLOYEE_NAME } {`(${e.EMPLOYEE_ID})`}</td>
                    <td>{e.AUTHORIZER_TYPE}</td>
                    <td>{e.STATUS}</td>
                    
                  </tr>
                    )
                  }
                  
                </tbody>
              </table>
            </div>
            }
            </div>;
          case 'terms':
            return <div className={`w-full flex flex-col rounded-md ${selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?'border-none':'border-[0.2px] p-2 border-gray-300'} `}> 
           { 
           selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?null:
           <div className=' flex flex-row justify-between'>
              <h3 className={` text-${gray700} font-semibold text-sm`}>This template will launch if the following terms are true.</h3>
             
              <p className='mr-12'>
                <label onClick={()=>setTermModal(true)} htmlFor="my-modal-10" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                  </label></p>
            </div>}
            {
               selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?null:
              <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

            </div>
            }

            {
              selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?null:

              isTermListLoading?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
              </div>
              : 
              termList.length ===0?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>ADD TERM</h4>
              </div>
              : 
              
              <div className="overflow-x-auto ">
  <table className="table w-full mt-1 mb-10">
    {/* head */}
    <thead>
      <tr>
        <th></th>
        <th>Document Type</th>
        <th>Term</th>
        <th>Ratio</th>
        <th>Value</th>
        <th>Status</th>
        
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
      {
        
        termList.map((e,index)=>
        <tr key={index}>
      <th>
       <label onClick={()=>deleteTerm(e.AT_ID)} className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
                  </label>
       </th>
        <td>{e.DOC_TYPE}</td>
        <td>{e.TERM}</td>
        <td>{e.RATIO}</td>
        <td>{e.VALUE}</td>
        <td>{e.STATUS}</td>
        
      </tr>
        
        )
      
      }
      
    </tbody>
  </table>
</div>}
            </div>;
          case 'users':
            return <div className=' w-full flex flex-col rounded-md border-[0.2px] p-2 border-gray-300'> 
            <div className=' flex flex-row justify-between'>
              <h3 className={` text-${gray700} font-semibold text-sm`}>Only following users have the access to this template.</h3>
              <p className='mr-12'>
                <label onClick={()=>{setUserModal(true)}} htmlFor="my-modal-11" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                  </label></p>
            </div>
            <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

            </div>

            {
              isStageUserLoading?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
              </div>
              : 
              stageUsers.length ===0?
              <div className=' w-full h-28 flex justify-center items-center'>
                
                  <h4 className={` text-sm text-${gray600} font-semibold`}>ADD USER</h4>
              </div>
              :
              
              
              <div className="overflow-x-auto ">
  <table className="table w-full mt-1 mb-10">
    {/* head */}
    <thead>
      <tr>
        <th></th>
        <th>USER NAME</th>
       
        <th>Status</th>
        
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
      {
       stageUsers.map((e,index)=>
       
       <tr key={index}>
       <th>
        <label onClick={()=>{delStageUser(e.AO_ID)}} className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
                   {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-amber-400 ">
             <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
           </svg> */}
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
                   </label>
        </th>
         <td>{e.EMPLOYEE_NAME}</td>
        
         <td>{e.STATUS}</td>
         
       </tr>
       ) 
       }
      
    </tbody>
  </table>
</div>}
            </div>;
         
          default:
            return null
        }
      })()}
   
   
  </div>
</div>
       {/* end edit approval template */}

       {/* start add stage modal */}

       <input type="checkbox" id="my-modal" className="modal-toggle" checked={stageModal}/>
<div className="modal">
  <div className="modal-box ">
  <label htmlFor="my-modal" onClick={()=>{setStageModal(false)}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Add Approval Stages</h3>
    <div className={` w-full h-[0.2px] bg-${gray300} my-2`}>

    </div>
    <div className=' flex flex-col items-start space-y-4 my-4'>
    {/* <input ref={stageSequenceRef} onChange={(e)=>{setStageSequenceCnt(e.target.value=stageDetails.length+1)}} type="number" placeholder="Enter Sequence" className="input input-bordered  w-full focus:outline-none   " /> */}
    <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
          <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{stageDetails.length+1}</p>
        </div>
    
    {addStageError.sategSequenceCnt && <span className={`text-${red500}`}>{addStageError.sategSequenceCnt}</span>}
    
<div className="modal-action w-full">
      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
        <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
          <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName===''? "Add user": selectedUserName}</p>
        </div>
      </label>
    </div>
    {addStageError.selectedUserName && <span className={`text-${red500}`}>{addStageError.selectedUserName}</span>}
    
<input ref={stageStatusRef} onChange={(e)=>{setStageStatusCnt(e.target.value)}}  type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " />
{addStageError.sateStatusCnt && <span className={`text-${red500}`}>{addStageError.sateStatusCnt}</span>}
{
  
  <select placeholder='Select Authorizer Type' value={authorizerName} onChange={(e)=> {selectedAuthorizerId=e.target.value;  setAuthorizerId(selectedAuthorizerId); authName=e.AUTHORIZER_TYPE; console.log(authName); setAuthorizerName(e.AUTHORIZER_TYPE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Authorizer Type</option>
  {
    authorizers.map((e,index)=>
    <option  key={index} value={e.AU_ID}>{e.AUTHORIZER_TYPE}</option>
    )
  }
 
  
</select>
  
}
{addStageError.authorizerName && <span className={`text-${red500}`}>{addStageError.authorizerName}</span>}
   
    </div>
{
  informerError && (<p className=' text-red-600 text-sm'>Informer Sequence Has To Be 1</p>)
}
 
    <div className=' flex flex-row justify-between'>
    <div  className="modal-action">
      <label onClick={()=>{setStageModal(false);}}   htmlFor="my-modal" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${red500} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CANCEL</p>
        </div>
      </label>
    </div>
    <div className="modal-action">
      <label onClick={()=>{addStage();onCancelStageAddUpdate()}} htmlFor="my-modal" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${green600} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CREATE</p>
        </div>
      </label>
   

    </div>
    </div>
  </div>
</div>


       {/* end add stage modal */}

       {/* start add term modal */}
       <input type="checkbox" id="my-modal-10" className="modal-toggle" checked={termModal}/>
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-10" onClick={()=>setTermModal(false)} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Add Approval Terms</h3>
   <div className=' flex flex-col items-start my-4'>
   {
  selectedTemplate.MODULE_TYPE_NAME ==="LEAVE"?
  
 ( <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    leaveTypes.map((e,index)=>
    <option  key={index} value={e.OBJECT_ID}>{e.OBJECT_SUB_TYPE_CODE}</option>
    )
  }
 
  
</select>

)

:

selectedTemplate.MODULE_TYPE_NAME==="IOU"?

( <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    iouTypes.map((e,index)=>
    <option  key={index} value={e.IOU_ID}>{e.IOU_NAME}</option>
    )
  }
 
  
</select>

)
:
selectedTemplate.MODULE_TYPE_NAME==="MOVE ORDER"?
<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    moveOrderTypes.map((e,index)=>
    <option  key={index} value={e.MOT_ID}>{e.TYPE_NAME}</option>
    )
  }
 
  
</select>




:
selectedTemplate.MODULE_TYPE_NAME==="TRANSPORT MOVE ORDER"?
<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    moveOrderTypes.map((e,index)=>
    <option  key={index} value={e.MOT_ID}>{e.TYPE_NAME}</option>
    )
  }
 
  
</select>




:
selectedTemplate.MODULE_TYPE_NAME==="LOAN"?
<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    lonTypes.map((e,index)=>
    <option  key={index} value={e.LTYPE_ID}>{e.LTYPE_NAME}</option>
    )
  }
 
  
</select>


:
selectedTemplate.MODULE_TYPE_NAME==="EMPLOYEE REQUISITION"?
<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e)=> {selectDocumentIdGolbal=e.target.value;  setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Document Type</option>
  {
    
    <option   value="00">Requisition</option>
    
  }
 
  
</select>


:

null
  
}

{addTermError.selectedLeaveName && <span className={`text-${red500}`}>{addTermError.selectedLeaveName}</span>}


{
   selectedTemplate.MODULE_TYPE_NAME ==="LEAVE"?
   <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="Leave Days">Leave Days</option>
    
  
   
 </select>
 :
 selectedTemplate.MODULE_TYPE_NAME==="IOU"?
 
 <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="Iou Amount">Iou Amount</option>
    
  
   
 </select>
 
 
 :
 
 selectedTemplate.MODULE_TYPE_NAME==="MOVE ORDER"?
 
 <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="move order Amount">Move Order Amount</option>
    
  
   
 </select>
 
 
 :
 selectedTemplate.MODULE_TYPE_NAME==="TRANSPORT MOVE ORDER"?
 
 <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="transport move order Amount">Transport Move Order Amount</option>
    
  
   
 </select>
 
 
 :
 
 
 selectedTemplate.MODULE_TYPE_NAME==="LOAN"?
 
 <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="Loan Amount">Loan Amount</option>
    
  
   
 </select>
 :
 selectedTemplate.MODULE_TYPE_NAME==="EMPLOYEE REQUISITION"?
 
 <select placeholder='Select Term' value={selectedTermName} onChange={(e)=> {setLeaveTerm(e.target.value)  }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Term</option>
   
     <option  value="Requisition Amount">Requisition Amount</option>
    
  
   
 </select>
 :
 
 
 null

}

{addTermError.leaveTerm && <span className={`text-${red500}`}>{addTermError.leaveTerm}</span>}

{
   selectedTemplate.MODULE_TYPE_NAME ==="LEAVE"?
   <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
   <option value='' disabled selected>Select Ratio</option>
   
   {
    leaveRatioList.map((e,index)=>
    <option  key={index} value={e.value}>{e.name}</option>
    )
  }
    
  
   
 </select>
 :

 selectedTemplate.MODULE_TYPE_NAME ==="IOU"?
 <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
 <option value='' disabled selected>Select Ratio</option>
 
 {
  iouRatioList.map((e,index)=>
  <option  key={index} value={e.value}>{e.name}</option>
  )
}
  

 
</select>
 
 
 :
 
 selectedTemplate.MODULE_TYPE_NAME ==="MOVE ORDER"?
 <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
 <option value='' disabled selected>Select Ratio</option>
 
 {
  moveOrderRatioList.map((e,index)=>
  <option  key={index} value={e.value}>{e.name}</option>
  )
}
  

 
</select>
 
 
 :
 selectedTemplate.MODULE_TYPE_NAME ==="TRANSPORT MOVE ORDER"?
 <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
 <option value='' disabled selected>Select Ratio</option>
 
 {
  moveOrderRatioList.map((e,index)=>
  <option  key={index} value={e.value}>{e.name}</option>
  )
}
  

 
</select>
 
 
 :
 
 selectedTemplate.MODULE_TYPE_NAME ==="LOAN"?
 <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
 <option value='' disabled selected>Select Ratio</option>
 
 {
  loanRatioList.map((e,index)=>
  <option  key={index} value={e.value}>{e.name}</option>
  )
}
  

 
</select>
 
 
 :
 selectedTemplate.MODULE_TYPE_NAME ==="EMPLOYEE REQUISITION"?
 <select placeholder='Select Ratio' value={seletedRationName} onChange={(e)=> {selectedRatioValue=e.target.value;setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }}  className="select select-bordered w-full focus:outline-none mb-2">
 <option value='' disabled selected>Select Ratio</option>
 
 {
  requisitionRatioList.map((e,index)=>
  <option  key={index} value={e.value}>{e.name}</option>
  )
}
  

 
</select>
 
 
 :
 
 null

}

{addTermError.seletedRationName && <span className={`text-${red500}`}>{addTermError.seletedRationName}</span>}
   <input ref={termValueRef} onChange={(e)=>{setTermValue(e.target.value)}}  type="number" placeholder="Enter value" className="input input-bordered  w-full focus:outline-none mb-3  " />
   {addTermError.termValue && <span className={`text-${red500}`}>{addTermError.termValue}</span>}
   <input ref={termStatusRef} onChange={(e)=>{setTermStatus(e.target.value)}}  type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " />
   {addTermError.termStatus && <span className={`text-${red500}`}>{addTermError.termStatus}</span>}
   </div>
   <div className=' flex flex-row justify-between'>
    <div  className="modal-action">
      <label onClick={()=>{onAddTermCancel(); setTermModal(false)}}   htmlFor="my-modal-10" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${red500} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CANCEL</p>
        </div>
      </label>
    </div>
    <div className="modal-action">
      <label onClick={()=>{insertTerm();onAddTermCancel()}} htmlFor="my-modal-10" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${green600} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CREATE</p>
        </div>
      </label>
   

    </div>
    </div>
  </div>
</div>

       {/* end add term modal */}

       {/* start add  user modal */}
       <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={userModal} />
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-11" onClick={(e)=>{setUserModal(false)}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Add User </h3>
    <div className=' flex flex-col items-start space-y-4 my-4'>
    {/* <input ref={stageSequenceRef} onChange={(e)=>{setStageSequenceCnt(e.target.value)}} type="number" placeholder="Enter Sequence" className="input input-bordered  w-full focus:outline-none   " /> */}
    
<div className="modal-action w-full">
      <label onClick={()=>{setSelectedUser({});setUserId('');setSelectedUserName('')}} htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
        <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
          <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName===''? "Add user": selectedUserName}</p>
        </div>
      </label>
    </div>
    {addUserError.selectedUserName && <span className={`text-${red500}`}>{addUserError.selectedUserName}</span>}
<input ref={userStatusRef} onChange={(e)=>{setUserStatusCnt(e.target.value)}} type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " />
{addUserError.userStatusCnt && <span className={`text-${red500}`}>{addUserError.userStatusCnt}</span>}
   
    </div>
  
    <div className=' flex flex-row justify-between'>
    <div  className="modal-action-11">
      <label onClick={()=>{setUserModal(false);setSelectedUser({});setUserId('');setSelectedUserName(''); setUserStatusCnt(''); userStatusRef.current.value=""}}  htmlFor="my-modal-11" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${red500} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CANCEL</p>
        </div>
      </label>
    </div>
    <div className="modal-action-11">
      <label onClick={()=>{insertStageUser(); setSelectedUser({});setUserId('');setSelectedUserName(''); setUserStatusCnt(''); userStatusRef.current.value=""}} htmlFor="my-modal-11" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${green600} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CREATE</p>
        </div>
      </label>
   

    </div>
    </div>
  </div>
</div>

       {/* end add  user modal */}

       {/* start edit template master */}
       <input type="checkbox" id="my-modal-12" className="modal-toggle" />
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-12" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
    <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
    <div className="modal-action">
      <label htmlFor="my-modal-12" className="btn">Yay!</label>
    </div>
  </div>
</div>

       {/* end edit template master */}

       {/* start edit stage modal */}
       <input type="checkbox" id="my-modal-13" className="modal-toggle" />
<div className="modal">
  <div className="modal-box">
  
  <label htmlFor="my-modal-13" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Edit Approval Stages</h3>
    <div className={` w-full h-[0.2px] bg-${gray300} my-2`}>

    </div>
    <div className=' flex flex-col items-start space-y-4 my-4'>
    {/* <input ref={stageSequenceRef} onChange={(e)=>{setStageSequenceCnt(e.target.value)}} type="number" placeholder="Enter Sequence" className="input input-bordered  w-full focus:outline-none   " /> */}
    
<div className="modal-action w-full">
      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
        <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
          <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName===''? "Add user": selectedUserName}</p>
        </div>
      </label>
    </div>
{/* <input ref={stageStatusRef} onChange={(e)=>{setStageStatusCnt(e.target.value)}}  type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " /> */}
{
  
  <select placeholder='Select Authorizer Type' value={authorizerName} onChange={(e)=> {selectedAuthorizerId=e.target.value;  setAuthorizerId(selectedAuthorizerId); setAuthorizerName(e.AUTHORIZER_TYPE)}}  className="select select-bordered w-full focus:outline-none mb-2">
  <option value='' disabled selected>Select Authorizer Type</option>
  {
    authorizers.map((e,index)=>
    <option  key={index} value={e.AU_ID}>{e.AUTHORIZER_TYPE}</option>
    )
  }
 
  
</select>
  
}
   
    </div>
    <div className=' flex flex-row justify-between'>
    <div className=' flex flex-row space-x-1'>
    <div className="modal-action">
      <label onClick={onCancelStageAddUpdate} htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={` bg-blue-500 h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CANCEL</p>
        </div>
      </label>
    </div>
    <div className="modal-action">
      <label htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${red500} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>DELETE</p>
        </div>
      </label>
    </div>
    </div>
    <div className="modal-action">
      <label htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
        <div className={`bg-${green600} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
          <p className=' pt-2'>CREATE</p>
        </div>
      </label>
    {/* 9 tarikh sesh korlam */}
    {/* main theke sokal a push */}

    </div>
    </div>
  </div>
</div>
       {/* end edit stage modal */}

       {/* start edit terms modal */}
       <input type="checkbox" id="my-modal-14" className="modal-toggle" />
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-14" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
    <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
    <div className="modal-action">
      <label htmlFor="my-modal-14" className="btn">Yay!</label>
    </div>
  </div>
</div>
       {/* end edit terms modal */}

       {/* start edit users modal */}

       <input type="checkbox" id="my-modal-15" className="modal-toggle" />
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-15" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
    <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
    <div className="modal-action">
      <label htmlFor="my-modal-15" className="btn">Yay!</label>
    </div>
  </div>
</div>

       {/* end edit users modal */}

       {/* start select user modal */}
       <input type="checkbox" id="my-modal-16" className="modal-toggle" />
<div className="modal">
  <div className="modal-box h-3/4">
  <label onClick={(e)=>{setUsers([]); setSearchKey('');
      usersRef.current.value='';}} htmlFor="my-modal-16" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 className="font-bold text-lg">Select User</h3>
   <div className='w-full flex flex-col justify-center items-center my-3'>
   <div className="form-control">
  <div className="input-group ">
    <input ref={usersRef} onChange={(e)=>{setSearchKey(e.target.value)}} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none" />
    <button onClick={getUsers} className="btn btn-square">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
    </button>
  </div>
</div>

{
 
 users.length ===0?
 <p className=' mt-16 font-medium text-lg'>Search Name Here</p>:
 isUsersLoading?
  <p>loding...</p>:
  
    users.map((e,index)=>
    <div key={index} onClick={(v)=>{setSelectedUser(e); selectedUserId=e.EMPLOYEE_ID; setUserId(selectedUserId); setSelectedUserName(e.EMPLOYEE_NAME)}} className="modal-action w-[336px]">
      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
        <div className={` w-full h-12 shadow-md  rounded-md flex text-left justify-center items-center`}>
          <p className={`w-full  pl-6 text-${gray600} text-[16px]`}>{`${e.EMPLOYEE_NAME} (${e.POSITION_NAME})`}</p>
        </div>
      </label>
    </div>
    )
  
}

   </div>
    
  </div>
</div>

       {/* end select user modal */}

    </div>
  )
}
