


  const getAllLeaveType= async(url)=>{


    const response = await   fetch(url,
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            // body: JSON.stringify({
            //     "P_OFFSET":page,
            //     "P_LIMIT":limit
            // }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
        //   console.log(data);
          return data;

     
}
export default getAllLeaveType
