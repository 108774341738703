


  const getDailyRoutine= async(url,page,limit,fDate,tDate)=>{
    console.log(`fdate: ${fDate}`);
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "P_OFFSET":page,
                "P_LIMIT":limit,
                "FROM_DATE":fDate,
                "TO_DATE":tDate

            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
        //   console.log(data);
          return data;

     
}
export default getDailyRoutine
