


const DateWiseEmployeeInfoService = async ( cdate) => {
    const dateWiseUrl=`http://wsaps4h01.weplbd.com:8001/sap/opu/odata/SAP/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$filter=(creation_date eq '${cdate}')&$format=json`;
    const uname="ABAPCONS3";
    const pass="Abap@cons456";
      // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
      console.log(`date wise url: ${dateWiseUrl}`);
      const response = await fetch(dateWiseUrl,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  // "Access-Control-Allow-Headers" : "Content-Type",
                  // "Access-Control-Allow-Origin": "http://localhost:3000",
                  // "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                  'Authorization': 'Basic ' + btoa(`${uname}:${pass}`),
              },
              
          }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
      const data = await response.json();
      console.log(`odata: ${data}`);
      return data;
  
  
  }
  export default DateWiseEmployeeInfoService
  