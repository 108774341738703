
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import EmployeeInfoService from '../../services/EmployeeInfoService';
import Loading from '../loader/Loading';
import { red500 } from '../../utils/colors';
import addEmpFromSapService from '../../services/AddEmpFromSapService';
import DateWiseEmployeeInfoService from '../../services/DateWiseEmpInfoService';

const insertUrl="http://118.179.60.93:8085/ords/wepl/hrlm/user_insertion_from_sap";


const columns = [
  { id: 'employee_id', label: 'EMPLOYEE ID', minWidth: 170 },
  { id: 'employee_name', label: 'EMPLOYEE NAME', minWidth: 170 },
  {
    id: 'joining_date',
    label: 'JOINING DATE',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'mobile_number',
    label: 'MOBILE NUMBER',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'email',
    label: 'EMAIL',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'date_of_birth',
    label: 'DATE OF BIRTH',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'employment_status',
    label: 'EMPLOYMENT STATUS',
    minWidth: 200,
    align: 'right',
    
  },
  {
    id: 'gender',
    label: 'GENDER',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'position_id',
    label: 'POSITION ID',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'department_id',
    label: 'DEPARTMENT ID',
    minWidth: 170,
    align: 'right',
  
  },
 
  {
    id: 'permanent_date',
    label: 'PERMANENT DATE',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'manager_id',
    label: 'MANAGER ID',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'blood_group',
    label: 'BLOOD GROUP',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'plant_id',
    label: 'PLANT ID',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'ws_rules',
    label: 'WS RULES',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'creation_date',
    label: 'CREATION DATE',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'maritial_status',
    label: 'MARITIAL STATUS',
    minWidth: 170,
    align: 'right',
    
  }
];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }



export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openClose,setOpenClose]=useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  var formatted;
  const [creationDate, setCreationDate] = useState(null);
  
  const url="http://wsaps4h01.weplbd.com:8001/sap/opu/odata/SAP/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$format=json";
  // const url=`https://wsaps4h01.weplbd.com:5201/sap/opu/odata/SAP/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$format=json&$top=2&$skip=2`;
  const [infos,setInfos]=useState([]);
  const [isInfoLoading,setIsInfoLoading]=useState(true);
  const [uId,setUId]=useState('');
  const [loop,setLoop]=useState(1);
  const [value, setValue] = React.useState(null);
  const [dateWiseInfos,setDateWiseInfos]=useState([]);
  var i=0;
  
  // const [isSend,setisSend]=useState(0);
  // setIsInfoLoading(false);

  useEffect(()=>{
    const id=localStorage.getItem('userId');
    setUId(id);
     getInfo();
     
      
      },[])

      const handleCreationDateChange = (date) => {
    
         formatted=moment(date).format("DD.MM.YYYY");
        setCreationDate(formatted);
       setValue(date);
       getDateWiseInfo();
        console.log(creationDate);
      };

      const handleCleanCreationDate=()=>{
        setValue(null);
        setCreationDate(null);
        setDateWiseInfos([]);
        console.log(`birth: ${value}`);
        getInfo();
      }

      const getDateWiseInfo=async()=>{
        // console.log(dateWiseUrl);
        setInfos([]);
        setIsInfoLoading(true);
        const info=await DateWiseEmployeeInfoService(formatted);
        // setDateWiseInfos(info.d.results);
        setInfos(info.d.results);
        setIsInfoLoading(false);
        // console.log(`date wise info: ${info.d.results[0].employee_name}`);
        // console.log(`len: ${dateWiseInfos.length}`);
      }
  const getInfo=async()=>{
      const inf = await EmployeeInfoService(url);
      console.log(inf);
      setInfos(inf.d.results);
      // console.log(`infos: ${inf.d.results}`);
      setIsInfoLoading(false);
      console.log(infos.length);
  }

  const sendInfo=async()=>{
    setOpenClose(true);
    for(i;i<infos.length;i++){
      const send=await addEmpFromSapService(insertUrl,uId,infos[i]);
      console.log(i);
      console.log(send);
      setLoop(i+1);
    }
    
    setOpenClose(false);
    setLoop(0);

    
  }

  return (
    <div className=' mt-24 ml-16'>
        <div className=' flex flex-row justify-between my-3'>
        <h4 className=' text-black font-semibold text-2xl  '>Employee Info</h4>
        <DatePicker
            appearance="default"
            size="lg"
            format="dd-MM-yyyy"
            placeholder="Select Creation Date and Sync"
            onChange={handleCreationDateChange}
            value={value}
            onClean={handleCleanCreationDate}
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
            style={{ width: 335, height: 50 }}
          />
          {/* disabled={dateWiseInfos.length ===0?true:false} */}
          {/* {creationDate!==null && dateWiseInfos.length===0?"LOADING...." :"SYNC WITH APP"} */}
        <button onClick={()=>{sendInfo()}}  className=' w-auto px-4 py-2 rounded-md shadow-md bg-green-600 text-white text-sm font-bold'>SYNC WITH APP</button>
        </div>
        {
           isInfoLoading?
           <div className=' flex justify-center items-center  w-full h-screen'>
             <Loading/>
           </div>
           :
           infos.length===0?
           <div className=' flex justify-center items-center w-full h-screen'>
   
                   <h1 className={`text-${red500} font-bold text-2xl`}>No Data Found</h1>
   
               </div>
   
           :
          
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label="sticky table ">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor:  '#dadada', color:'black', fontSize:"14px"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {infos
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={infos.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>}

    <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={openClose} />
<div className="modal">
  <div className="modal-box">
  {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}
   
    
       <div className=' w-full h-72 flex items-center justify-center bg-white'>
        <h1 className=' text-green-500 font-bold text-xl'>{`Inserting ${loop} of ${infos.length}`}</h1>
       </div>

    
  
    
  </div>
</div>
    </div>
  );
}
