


const addEmpFromSapService = async (url,adminId,empData) => {
    console.log(`z: ${adminId} empId: ${empData.employee_id}`);
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
  
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify(
            {
            // "Z_DATA": adminId,
            // "EMPLOYEE_ID": "7654321",
            // "EMPLOYEE_NAME": empList[55].employee_name,
            // "COMPANY_CODE": empList[55].company_id,
            // "PLANT_CODE": empList[55].plant_id,
            // "EMAIL": empList[55].email,
            // "JOINING_DATE": empList[55].joining_date,
            // "DATE_OF_BIRTH": empList[55].date_of_birth,
            // "MOBILE_NUMBER": empList[55].mobile_number,
            // "MANAGER_ID": empList[55].manager_id,
            // "EMPLOYMENT_STATUS": empList[55].employment_status,
            // "POSITION_ID": empList[55].position_id,
            // "DEPARTMENT_ID":empList[55].department_id,
            // "JOB_ID": '',
            // "GENDER": empList[55].gender,
            // "MARITIAL_STATUS": empList[55].maritial_status,
            // "BLOOD_GROUP": empList[55].blood_group,
            // "NID": '',
            // "WS_RULES": empList[55].ws_rules,
            "Z_DATA": adminId,
            "EMPLOYEE_ID": empData.employee_id===""?"":empData.employee_id,
            "EMPLOYEE_NAME": empData.employee_name===""?"":empData.employee_name ,
            "COMPANY_CODE": empData.company_id===""?"":empData.company_id,
            "PLANT_CODE": empData.plant_id===""?"":empData.plant_id,
            "EMAIL": empData.email===""?`e${empData.employee_id}@example.com`:empData.email.toLowerCase(),
            "JOINING_DATE": empData.joining_date===""?"2021-02-01":empData.joining_date,
            "DATE_OF_BIRTH":empData.date_of_birth===""?"":empData.date_of_birth,
            "MOBILE_NUMBER": empData.mobile_number ===""?`${empData.employee_id}`:empData.mobile_number,
            "MANAGER_ID": empData.manager_id===""?"":empData.manager_id,
            "EMPLOYMENT_STATUS": empData.employment_status===""?"":empData.employment_status,
            "POSITION_ID": empData.position_id===""?"20000034":empData.position_id,
            "DEPARTMENT_ID": empData.department_id===""?"":empData.department_id,
            "JOB_ID": empData.job_id ===""?"30000017":empData.job_id,
            "GENDER":empData.gender===""?"":empData.gender,
            "MARITIAL_STATUS": empData.maritial_status===""?"":empData.maritial_status,
            "BLOOD_GROUP": empData.blood_group===""?"":empData.blood_group,
            "NID": "na",
            "WS_RULES": empData.ws_rules===""?"90":empData.ws_rules
  
        }
        ),
      }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    
   
    const data = await response.json();
    console.log(`emp insert: ${data}`);
  
  
  
    return data;
  
  
  }
  export default addEmpFromSapService
  