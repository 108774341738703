import React,{useState,useEffect,useRef} from 'react'
import moment from 'moment';
import { CSVLink } from "react-csv";
import Datepicker from "react-tailwindcss-datepicker";
import { gray500,gray100,red500,gray300,gray400,green100,green600,gray700,gray800,gray200,gray600 } from '../../utils/colors';
import  getDailyRoutine  from '../../services/DailyRoutineService';
import getDownloadDailyRoutine from '../../services/DownloadDailyRoutineService';
import getSearchedDailyRoutine from '../../services/DailyRoutineSearchService';

import Loading from '../loader/Loading';
import AttendanceImageService from '../../services/AttendanceImageService';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";


const url="http://118.179.60.93:8085/ords/wepl/hrlm/daily_attendance_web";
const urlAllDaily="http://118.179.60.93:8085/ords/wepl/hrlm/daily_attendance_all_web";
const searchUrl="http://118.179.60.93:8085/ords/wepl/hrlm/daily_attendance_search_web";
const attImageurl="http://118.179.60.93:8085/ords/wepl/hrlm/attendance_picture";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#dadada",
      color: "#777777",
      fontWeight:"bold"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      
    },
  }));





export default function DailyAttendance() {
    // let page=0;
    let limit=10;
    // const [flag, setFlag] = useState(false);
    let [pageCount,setPage]=useState(1);
    let [page,setPg]=useState(0);
        const [isLoading,setIsLoading]=useState(true);
        const [data,setData]=useState([]);
        const [processedData, setProcessedData] = useState([]);
        const [searchKey,setSearchKey]=useState('');
        const [searchKey2,setSearchKey2]=useState('');
        const [userId,setUserId]=useState('');
        const [openClose,setOpenCLose]=useState(false);
        const [openClose2,setOpenCLose2]=useState(false);
        const [picUrl,setPicUrl]=useState(null);
        const [isImageLoading,setIsImageLoading]=useState(true);
        const [dateValue, setDateValue] = React.useState(null);
        const [startDate,setStartDate]=useState('');
        const [endDate,setEndDate]=useState('');
        const [value, setValue] = useState({
          startDate: null,
          endDate: null
  
      });
        const inputRef = useRef(null);
        const inputRef2 = useRef(null);
        let id=null,userName=null;
       const fromDate=moment(Date()).format('YYYY-MM-DD');
      const  toDate=moment(Date()).format('YYYY-MM-DD');
        let fileName= moment(Date()).format('DD/MM/YYYY');
      const  headers = [
            { label: "Attendace Date", key: "ATTENDANCE_DATE" },
            { label: "Employee Id", key: "EMPLOYEE_ID" },
            { label: "Employee Name", key: "EMPLOYEE_NAME" },
            { label: "Log In", key: "LOG_IN" },
            { label: "Log Out", key: "LOG_OUT" },
            { label: "Late", key: "LATE" },
            { label: "Log In Site", key: "PLANT_NAME" },
            { label: "Log In Remarks", key: "LOG_IN_REMARKS" },
            { label: "Log Out Remarks", key: "LOG_OUT_REMARKS" },
            
            
          ];
        // const url="http://118.179.60.93:8085/ords/wepl/hrlm/daily_attendance_web";
            useEffect(()=>{
                setStartDate(fromDate);
                // setEndDate(toDate);
                const id=localStorage.getItem('userId');
                setUserId(id);
                
                getDailyRtn(fromDate,fromDate);
                // const getDownloadRtn=async()=>{
                //     const re =await getDownloadDailyRoutine(urlAllDaily);
                //     const dd=re.ATTENDANCE_DETAILS;
                //     console.log(`all: ${dd}`);
                 
                    
                // }
                
                // getDownloadRtn();
               
            },[ ]);

            const handleValueChange = (newValue) => {
              console.log("start:", moment(newValue.startDate).format("YYYY-MM-DD"));
              console.log("end:", moment(newValue.endDate).format("YYYY-MM-DD"));
              setValue(newValue);
          }

            const getDailyRtn=async(f,t)=>{
              const result= await getDailyRoutine(url,page,limit,f,t);
              setIsLoading(false);
              setData(result.ATTENDANCE_DETAILS);
              processData(result.ATTENDANCE_DETAILS);
              // setDataInCSV(result.ATTENDANCE_DETAILS);
              
              // console.log(result.ATTENDANCE_DETAILS);
            
              // console.log(data);
             
          };

            const handleDateChange = (date) => {
    
            const  formatted=moment(date).format("YYYY-MM-DD");
            setData([]);
            setIsLoading(true);
             setStartDate(formatted);
            setDateValue(date);
            getDailyRtn(formatted,formatted);
            
           };
     
           const handleCleanDate=()=>{
             setDateValue(null);
             setStartDate(null);
             setData([]);
             setStartDate(fromDate);
             console.log(`birth: ${dateValue}`);
             setData([]);
             setIsLoading(true);
             getDailyRtn(fromDate,fromDate);
           }

            const showImage=async(attId)=>{

                const im=await AttendanceImageService(attImageurl,userId,attId);
                base64ToImageUrl(im.PICTURE);
            }

            const base64ToImageUrl = (base64String) => {
                const blob = base64ToBlob(base64String);
                const url = URL.createObjectURL(blob);
                setPicUrl(url);
                setIsImageLoading(false);
              };
              
              const base64ToBlob = (base64String) => {
                const byteCharacters = atob(base64String);
                const byteArrays = [];
              
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteArrays.push(byteCharacters.charCodeAt(i));
                }
              
                const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
                return blob;
              };

            const search=async(f,t)=>{
                // event.preventDefault();
              
                setIsLoading(true);
                setData([]);
                id=searchKey;
                // if(!isNaN(searchKey)){
                //     id=searchKey;
                // }
                // else{
                //     userName=searchKey;
                // }

                console.log(`id: ${id} userName: ${userName}`);

                const result=await getSearchedDailyRoutine(searchUrl,id,userName,f,t);
              console.log(`ssss: ${result.ATTENDANCE_DETAILS}`);
                setData(result.ATTENDANCE_DETAILS);
                processData(result.ATTENDANCE_DETAILS);
                setIsLoading(false);
                console.log(`len: ${data.length}`);
                

                setSearchKey("");
                inputRef.current.value = "";
            }

            const processData=(data)=>{
                const newData = data.map((obj) => {
                    const newDate = new Date(obj.ATTENDANCE_DATE);
                    const formattedDate =moment(newDate).format('DD/MM/YYYY');
                    const late=calculateTimeDifference(obj.LOG_IN,obj.OFFICE_BUFFER_TIME);
                    // newDate.toLocaleString("en-US", {
                    //     day: "2-digit",
                    //     month: "2-digit",
                    //     year: "numeric",
                    //     // hour: "numeric",
                    //     // minute: "numeric",
                    //     // hour12: true,
                    //   });
                    // const formattedDate = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()} ${newData.getTime()}`;
                    return { ...obj, ATTENDANCE_DATE: formattedDate, LATE:late };
                  });
                  setProcessedData(newData);
                  
                  console.log(newData);
            }
           
           
            const next=async()=>{
             
               
                // setPage((page)=>page+10);
                const newPage = page + 10;
                setPg(newPage);
                console.log(`page here ${newPage}`);
                setPage((pre)=>pre+1);
              
              
                setIsLoading(true);
                setData([]);
              
                    const result= await getDailyRoutine(url,newPage,limit,startDate,startDate);
                    setIsLoading(false);
                setData(result.ATTENDANCE_DETAILS);
                console.log(result.ATTENDANCE_DETAILS);
              
                
                console.log('clicked');
                
                
              
                
            }
          
           
            const previous=async()=>{
             
                // setPage((pre)=>pre-10);
                const newPage = page - 10;
                setPg(newPage);
                console.log(`page here ${newPage}`);
                setPage((pre)=>pre-1);
               
                console.log('clicked');
                setIsLoading(true);
                setData([]);
                
                const result= await getDailyRoutine(url,newPage,limit,startDate,startDate);
                
                setIsLoading(false);
                setData(result.ATTENDANCE_DETAILS);
                console.log(result.ATTENDANCE_DETAILS);
            }


            const calculateTimeDifference = (loginTime, officeStartTime) => {
              // Create Date objects for login time and office start time
              const loginDate = new Date(`1970-01-01 ${loginTime}`);
              const officeStartDate = new Date(`1970-01-01 ${officeStartTime}`);
            
              // Calculate the time difference in milliseconds
              const timeDiff = loginDate.getTime() - officeStartDate.getTime();
            
              // Check if login time is greater than office start time
              if (timeDiff > 0) {
                // Convert the time difference to minutes or hours
                let result = '';
                const minutesDiff = Math.floor(timeDiff / 1000 / 60);
                if (minutesDiff >= 60) {
                  const hoursDiff = Math.floor(minutesDiff / 60);
                  const remainingMinutesDiff = minutesDiff % 60;
                  result = `${hoursDiff} h : ${remainingMinutesDiff} m`;
                } else {
                  result = `${minutesDiff}m`;
                }
                return result;
              } else {
                return "N/A";
              }
            };
            
            // Example usage
            const loginTime = "12:58";
            const officeStartTime = "10:00 AM";
            const result = calculateTimeDifference(loginTime, officeStartTime);
            console.log(result);
            
            
         
            
          
    
           
  return (
    
    <div className=' mt-16 '>
        
        <div className=' w-full   bg-white rounded-lg shadow-lg mx-8  flex flex-col items-start'>
            <div className=' flex  flex-row justify-between items-center p-5 w-full h-16  bg-white'>
                <h4 className=' text-black font-semibold text-lg '>Daily Attendance</h4>
                <div className=' flex flex-row space-x-2'>
                
                <button onClick={()=>{setOpenCLose2(true)}} className={` w-auto h-9 flex justify-center items-center bg-${green600} px-2 rounded-md shadow-sm`}>
                             <h2 className=' text-sm text-white  font-bold'>Download By Employee</h2>
     
                         </button>
      
        
                
                         <DatePicker
                         className=' w-48 h-8'
            appearance="default"
            size="md"
            format="dd-MM-yyyy"
            placeholder="Select Date"
            onChange={handleDateChange}
            value={dateValue}
            onClean={handleCleanDate}
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
            // style={{ width: 335, height: 50 }}
          />
                  {
                    processedData &&(
                        <CSVLink data={processedData} headers={headers} filename={`attendance_${fileName}.csv`}>
                        <button className={` w-auto h-9 flex justify-center items-center bg-${green600} px-2 rounded-md shadow-sm`}>
                             <h2 className=' text-sm text-white  font-bold'>Download</h2>
     
                         </button>
                        </CSVLink>
                    )
                  }
                   
                    <input ref={inputRef} value={searchKey} type="text" onChange={(e)=>{setSearchKey(e.target.value)}} placeholder='id or name'  className='  border  border-gray-300 h-9 w-40 rounded-lg active:border active:border-blue-300 px-3 focus:outline-none' />
 <button onClick={()=>{search(fromDate,fromDate)}} type='submit'>
 <div className={`h-9 w-10 rounded-sm shadow-sm justify-center  bg-${gray100} px-2`}>
 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-9 text-${gray500} font-semibold`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>
 </div>
 </button>

                </div>
            </div>

            {/* end search and title */}

            {
              isLoading?
                  
                  
                    
              <Loading/>
            
            : 
            
      data.length===0 ?  
      <div className=' flex flex-row justify-center items-center text-center w-full h-screen '>
          
          <h1 className={`text-${red500} font-bold text-2xl `}>No Data Found</h1>

      </div>:
              
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            
            <StyledTableCell align="right">DATE</StyledTableCell>
            <StyledTableCell align="right">ID</StyledTableCell>
            <StyledTableCell align="right">NAME</StyledTableCell>
            <StyledTableCell align="right">IN</StyledTableCell>
            <StyledTableCell align="right">OUT</StyledTableCell>
            <StyledTableCell align="right">LATE</StyledTableCell>
            <StyledTableCell align="right">IN SITE</StyledTableCell>
            <StyledTableCell align="right">REMARKS(IN)</StyledTableCell>
            <StyledTableCell align="right">REMARKS(OUT)</StyledTableCell>
           
            <StyledTableCell align="right">PHOTO</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            
                {
                  
            
            data.map((e,index)=>
                       
            
<StyledTableRow key={index}>
<StyledTableCell component="th" scope="row">
  {
     e.ATTENDANCE_DATE==null?"N/A":  moment(e.ATTENDANCE_DATE).format('DD/MM/YYYY')
  }
</StyledTableCell>
<StyledTableCell align="right">{e.EMPLOYEE_ID == null? "N?A": e.EMPLOYEE_ID}</StyledTableCell>
<StyledTableCell align="right">{e.EMPLOYEE_NAME ==null ?"N/A":e.EMPLOYEE_NAME}</StyledTableCell>
<StyledTableCell align="right">{e.LOG_IN ==null ?"N/A":e.LOG_IN}</StyledTableCell>
<StyledTableCell align="right">{e.LOG_OUT == null? "N/A":e.LOG_OUT}</StyledTableCell>
<StyledTableCell align="right">{calculateTimeDifference(e.LOG_IN,e.OFFICE_BUFFER_TIME)}</StyledTableCell>
<StyledTableCell align="right">{e.PLANT_NAME == null? "N/A":e.PLANT_NAME}</StyledTableCell>
<StyledTableCell align="right">{e.LOG_IN_REMARKS == null ? "N/A": e.LOG_IN_REMARKS}</StyledTableCell>
<StyledTableCell align="right">{e.LOG_OUT_REMARKS ==null ?"N/A" : e.LOG_OUT_REMARKS}</StyledTableCell>

<StyledTableCell align="right">
<button onClick={()=>{setOpenCLose(true); showImage(e.ATDN_ROW_ID)}} className=' w-auto p-1 bg-green-500 shadow-md rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
</svg>


                </button>
</StyledTableCell>
</StyledTableRow>
                    
                    )
                   
                }
                 </TableBody>
      </Table>
    </TableContainer>
    
    }
                {/* </tbody>
  </table>
</div> */}
           
            

            {/* end content */}

            <div  className=' flex flex-row space-x-4 justify-end items-end mt-6 w-full pr-6'>
      <button  disabled={ page===0?true:false} onClick={previous}>
      <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5  ${page ===0?'text-white':`text-${gray600}`} font-bold`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
      </button>
 <p className={`text-${gray500} text-sm font-bold`}>
{pageCount}
    </p> 


            <button disabled={data.length ===0? true:false} onClick={next}>
            <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5  ${data.length ===0?'text-white':`text-${gray600}`} font-bold`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
            </button>


            </div>

            {/* <div>
                {
                    pageCount
                }
                
                <button onClick={()=>{setPage((pre)=>pre+10)}}>
                    clck
                </button>
            </div> */}
             

           
        </div>
        <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={openClose} />
<div className="modal">
  <div className="modal-box">
  <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
   
    {
        isImageLoading?
        <p>Loading........</p>
        :
        <img src={picUrl} alt="attpic" className=' h-80 w-full p-4 shadow-md rounded-md'  />
        }
  
    
  </div>
</div>
        <input type="checkbox" id="my-modal-12" className="modal-toggle" checked={openClose2} />
<div className="modal">
  <div className="modal-box w-3/4 max-w-7xl  h-5/6">
  <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose2(false);}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
   
        <div className='  flex flex-col items-start space-y-8'>
       <div className=' flex flex-row space-x-6 h-12'>
       <Datepicker

placeholder='From Date - To Date         '
showFooter={true}
primaryColor={"green"}
showShortcuts={true}
value={value}
onChange={handleValueChange}
displayFormat={"DD/MM/YYYY"}
toggleClassName={`absolute bg-${green600}  rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
// inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100" 
// containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md" 
// toggleClassName=" text-gray-400"
inputClassName={`outline-green-600  border-2 border-green-600 w-[250px] rounded-r-lg h-full px-3`}
// containerClassName="  " 


/>
<input ref={inputRef} value={searchKey} type="text" onChange={(e)=>{setSearchKey(e.target.value)}} placeholder='Enter Employee Id'  className='  border  border-gray-300 h-12 w-52 rounded-lg active:border active:border-blue-300 px-3 focus:outline-none' />
       </div>

       <button 
       onClick={()=>{setOpenCLose2(false); search(value.startDate,value.endDate)}}
       className='  w-1/2 h-12 rounded-md justify-center items-center text-white shadow-sm bg-green-600 font-semibold  '>
        SEARCH

       </button>

        </div>
  
    
  </div>
</div>


    </div>

    

    
  )
}
