import React from 'react'
import DailyAttendance from '../daily_attendance/DailyAttendance'
import { useState,useEffect } from 'react'
import Home from './Home';
import MonthlyAttendancePage from '../monthly_attendance/MonthlyAttendancePage';
import ApprovalSetupPage from '../approval_setup/ApprovalSetupPage';
import { useNavigate } from 'react-router-dom';

import { gray500,gray100,red500,gray300,gray400,green100,green600 } from '../../utils/colors';
import AddEmployeePage from '../add_employee/AddEmployeePage';

import AdminInfoService from '../../services/AdminInfoService';
import getPropicService from '../../services/PropicService';
import AddLatLong from '../add_lat_long/AddLatLong';
import EmployeeInfoPage from '../employee_info/EmployeeInfoPage';
import StickyHeadTable from '../employee_info/EmployeeInfoPage2';
import GeoFencePage from '../add_lat_long/GeoFencePage';
import AddOfficeTimePage from '../add_office_time/AddOfficeTimePage';
import OfficeTimePage from '../add_office_time/OfficeTimePage';


const infoUrl="http://118.179.60.93:8085/ords/wepl/hrlm/my_info";
const getImageUrl="http://118.179.60.93:8085/ords/wepl/employee_propic/";

export default function HomePage() {
  
 const [menu,setMenu]=useState('daily');
 const [userId,setUserId]=useState('');
 const [adminDetails,setAdminDetails]=useState([]);
 const [isAdminLoading,setIsAdminLoading]=useState(true);
 const [picture,setPicture]=useState({});
 const [picUrl,setPicUrl]=useState(null);
 const navigate = useNavigate();


 
useEffect(()=>{
const id=localStorage.getItem('userId');
setUserId(id)
getAdminInfo(id);

},[])

const logOut=()=>{
  setTimeout(() => {
    navigate('/');
  }, 200);
}

const base64ToImageUrl = (base64String) => {
  const blob = base64ToBlob(base64String);
  const url = URL.createObjectURL(blob);
  setPicUrl(url);
};

const base64ToBlob = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
  return blob;
};




const propic=async(url,propicId)=>{
  const pic=await getPropicService(url,propicId);
  setPicture(pic);
  console.log(pic.propic_id);
  base64ToImageUrl(pic.propic);
  setIsAdminLoading(false);
  
}


const getAdminInfo=async(uid)=>{
  const admin=await AdminInfoService(infoUrl,uid);
  setAdminDetails(admin.EMP_DETAILS);
const eId=admin.EMP_DETAILS[0].EMPLOYEE_ID;
const picName=admin.EMP_DETAILS[0].PROPIC_NAME;
  propic(getImageUrl,admin.EMP_DETAILS[0].PROPIC_ID);
 
}
  

  return (
    <div className=' '>
        {/* navbar start */}

        <div className='  h-16 w-full  bg-white shadow-sm flex flex-row items-center justify-between fixed'>
    <div className=' flex flex-row space-x-6 ml-14'>
        <img src="/images/logo.png" alt="logo"  className=' h-8 w-8 rounded-full'/>
        {/* {picUrl} */}
        <h4 className={`text-lg font-medium text-${gray500}`}>WorkedIn</h4>
    </div>
    <div className='mr-14 flex flex-row space-x-6'>
      
   <div className="menu menu-horizontal">
   <li  tabIndex={0}>
   <div  className={`h-12 w-12 rounded-full bg-${gray100} p-2 items-center`} >
    <img src="/images/logo.png" alt="logo"  className=' h-8 w-8 rounded-full'/>
    
    </div>

    <ul className={`rounded-md  bg-${red500} shadow-lg`}>
            <li>
              
                <p onClick={()=>{logOut()}} className="text-white font-bold">Log Out</p>
              
            </li>
            
          </ul>

   </li>
   </div>
    
    
    </div>
   
        </div>

        {/* <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles}>
        {/* <div className=" top-20 right-20 bg-white p-4 ">
          <h1 className="text-lg font-bold mb-4">My Modal</h1>
          <p>This is the content of my modal.</p>
        </div> */}
      {/* </Modal> */} 
        
       
       

        {/* navbar end */}
      

        {/* body start */}
       

        <div className='flex flex-row  w-full mt-1  '>
            {/* left side */}
            <div className=' w-1/5 h-screen'>
             
            <div className={`fixed w-1/5 h-screen bg-white  p-4 flex flex-col space-y-2 border-r border-dotted border-${gray300} border-spacing-4 bg-fixed  mt-16`}>
                {
                  isAdminLoading?
                  <progress className="progress w-1/5"></progress>:
                 
                  
                  <div  className={` h-20 w-full bg-${gray100} rounded-md items-center p-3 flex flex-row space-x-3`}>
                <div className={`h-12 w-12 rounded-full bg-${gray300} p-2  items-center`}>
    <img src={picUrl} alt="logo"  className=' h-8 w-8 rounded-full'/>
    </div>
    {
      <div className=' flex flex-col  items-start'>
      <p className=' text-black font-medium text-sm'>{adminDetails[0].EMPLOYEE_NAME}</p>
      <p className={`text-${gray400} font-medium text-xs`}>admin</p>
  </div>
    }
                </div>
                  
                }

                {/* menu item */}

                {/* <button  onClick={()=>setMenu('home')} className={` h-12 w-full ${menu==='home'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

                <svg 
                
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="home"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>

<p className={`${menu ==='home'? `text-${green600}` :`text-${gray500} `}  ${menu==="home"? ' font-bold': 'font-medium'} text-sm`}>Home</p>


                </button> */}
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('approval')} className={` h-12 w-full ${menu==='approval'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="approval"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
</svg>


<p className={`${menu ==='approval'? `text-${green600}` :`text-${gray500} `}  ${menu==="approval"? ' font-bold': 'font-medium'} text-sm`}>Approval Setup</p>


                </button>
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('daily')} className={`h-12 w-full  ${menu === "daily"? `bg-${green100}` : 'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center active:bg-green-100`}>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="daily"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>



<p className={` ${menu ==="daily"? `text-${green600}` :`text-${gray500} `} ${menu==="daily"? ' font-bold': 'font-medium'} text-sm`}>Daily Attendance</p>


                </button>
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('monthly')} className={` h-12 w-full ${menu==='monthly'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="monthly"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
</svg>




<p className={` ${menu ==="monthly"? `text-${green600}` :`text-${gray500} `} ${menu==="monthly"? ' font-bold': 'font-medium'} text-sm text-start`}>Monthly Attendance Report</p>


                </button>
                {/* end menu item */}
                {/* menu item */}

                {/* <button onClick={()=>setMenu('update')} className={` h-12 w-full ${menu==='update'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="update"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
</svg>





<p className={` ${menu ==="update"? `text-${green600}` :`text-${gray500} `} ${menu==="update"? ' font-bold': 'font-medium'} text-sm`}>Attendance Update</p>


                </button> */}
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('remote')} className={` h-12 w-full ${menu==='remote'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="remote"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
</svg>






<p className={` ${menu ==="remote"? `text-${green600}` :`text-${gray500} `} ${menu==="remote"? ' font-bold': 'font-medium'} text-sm`}>Geo Fence</p>


                </button>
                {/* end menu item */}
                {/* menu item */}

                
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('info')} className={` h-12 w-full ${menu==='info'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

     
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${menu ==="info"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
</svg>









<p  className={` ${menu ==="info"? `text-${green600}` :`text-${gray500} `} ${menu==="info"? ' font-bold': 'font-medium'} text-sm`}>Employee Info</p>


                </button>
                {/* end menu item */}
                {/* menu item */}

                <button  onClick={()=>setMenu('time')} className={` h-12 w-full ${menu==='time'? `bg-${green100}`:'bg-white'} rounded-md hover:bg-${gray100} p-4 flex flex-row space-x-3 items-center`}>

     
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"className={`w-6 h-6 ${menu ==="time"?'text-green-600' :'text-gray-500'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>










<p  className={` ${menu ==="time"? `text-${green600}` :`text-${gray500} `} ${menu==="time"? ' font-bold': 'font-medium'} text-sm`}>Set Office Time</p>


                </button>
                {/* end menu item */}
            </div>
             
            
            </div>
            

            {/* end left side */}
            {/* middle */}
           

            {/* end middle */}

            <div className=' w-3/4 h-screen'>
            {(() => {
        switch (menu) {
          // case 'home':
          //   return <Home/>
          case 'approval':
            return <ApprovalSetupPage/>
          case 'daily':
            return <DailyAttendance/>
          case 'monthly':
            return <MonthlyAttendancePage/>
          case 'remote':
            return <GeoFencePage/>
          case 'time':
            return <OfficeTimePage/>
            case 'info':
              return <StickyHeadTable/>
          default:
            return null
        }
      })()}
                {/* <DailyAttendance/> */}
            </div>

        </div>

        {/* body end */}
    </div>
  )
}
