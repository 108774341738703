


const getPropicService = async ( url,propicId) => {
    console.log(`uid: ${propicId}`);
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(`${url}${propicId}`,
        {
            method: "GET",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    console.log(`pic: ${data}`);
    return data;


}
export default getPropicService
