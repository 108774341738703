
import React, { useState, useEffect, useRef } from 'react'
import getCompanyList from '../../services/CompanyListService';
import getPlantList from '../../services/PlantListService';
import SuccessAlert from '../alert_component/SuccessAlert';
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600 } from '../../utils/colors';
import { useLocation } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';



import ErrorAlert from '../alert_component/ErrorAlert';
import AddOfficeTimeService from '../../services/AddOfficeTimeService';

const companyUrl = "http://118.179.60.93:8085/ords/wepl/hrlm/getCompanyList";
const plantUrl = "http://118.179.60.93:8085/ords/wepl/hrlm/get_plant_details";
const addTimeUrl="http://118.179.60.93:8085/ords/wepl/hrlm/attendance_time_setup_insert_update";

const psgList=[{"name":"Head Office","id":90},{"name":"Outside Head Office","id":95}];
export default function AddOfficeTimePage() {
    let selectedCompanyCode, positionId, jobId, selectedPalntCode, selectedModuleCode, selectedUserId, selectedAuthorizerId, selectPsgid, selectDocumentIdGolbal;

  
    const [company, setCompany] = useState([]);
    const [plant, setPlant] = useState([]);
    const [isPlantLoading, setIsPlantLoading] = useState(true);
    const [companyCode, setCompanyCode] = useState('');
    const [plantCode, setPlantCode] = useState('');
    const [selectedCompanyname, setSelectedCompanyName] = useState('');
    const [selectedPlantname, setSelectedPlantName] = useState('');
    const [adminId, setAdminId] = useState('');
    const [latlongError, setLatLongError] = useState({});
 
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
  
  
    const location = useLocation();
    const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
    const [value1, setValue1] = React.useState(dayjs('2022-04-17T15:30'));
    const [value2, setValue2] = React.useState(dayjs('2022-04-17T15:30'));
    const [startTime,setStartTime]=useState('');
    const [endTime,setEndTime]=useState('');
    const [bufferTime,setBufferTime]=useState('');
    const [psgId,setPsgId]=useState('');
    const [psgName,setPsgName]=useState('');
    const [selectedPsgName,setSelectedPsgName]=useState('');
    const [astId,setAtsId]=useState(null);
  
    useEffect(() => {
      const id = localStorage.getItem('userId');
      setAdminId(id);
      getCompany();
      if (location.state && location.state.office) {
        const { ATS_ID, COMPANY_CODE, COMPANY_NAME, PLANT_CODE, PLANT_NAME, OFFICE_START_TIME, OFFICE_END_TIME, OFFICE_BUFFER_TIME,PSG } = location.state.office;
       
        if (ATS_ID !== null) {
          console.log(PSG); //print psg value here
          
          if(PSG===90){   
            setPsgName("Head Office");
            setPsgId(PSG);
            console.log(psgId);
           
          }
          else {
            setPsgName("Outside Head Office");
            setPsgId(PSG);
           
          }
         
          setAtsId(ATS_ID);
          setPsgId(PSG);
          setCompanyCode(COMPANY_CODE);
          setSelectedCompanyName(COMPANY_NAME);
          setPlantCode(PLANT_CODE);
          setSelectedPlantName(PLANT_NAME);
          setStartTime(OFFICE_START_TIME);
          const st=covertTime(OFFICE_START_TIME);
          setValue(st);
          const et=covertTime(OFFICE_END_TIME);
          setValue1(et);
          const bt=covertTime(OFFICE_BUFFER_TIME);
          setValue2(bt);
          setEndTime(OFFICE_END_TIME);
          setBufferTime(OFFICE_BUFFER_TIME);
         
       
            // setIsPlantLoading(false);
            getPlant(COMPANY_CODE);
            console.log(psgId);
           
         
          
        }
      }
      
    
    }, []);

    const covertTime = (time) => {
      const currentTime = new Date();
      const [hours, minutes] = time.split(':');
      const [meridiem, amPm] = minutes.split(' ');
      
      currentTime.setHours(hours);
      currentTime.setMinutes(parseInt(amPm === 'AM' ? minutes : minutes) + (meridiem === 'PM' ? 12 * 60 : 0));
      
      const year = currentTime.getFullYear();
      const month = String(currentTime.getMonth() + 1).padStart(2, '0');
      const day = String(currentTime.getDate()).padStart(2, '0');
      const hour = String(currentTime.getHours()).padStart(2, '0');
      const minute = String(currentTime.getMinutes()).padStart(2, '0');
    
      return `${year}-${month}-${day}T${hour}:${minute}`;
    };
    
    const validateAddTime =  () => {
      const latlongError = {};
      if (startTime==='') {
        latlongError.startTime = "Enter Start Time";
      }
      if (endTime==='') {
        latlongError.endTime = "Enter End Time";
      }
      if (bufferTime==='') {
        latlongError.bufferTime = "Enter Buffer Time";
      }
      if (selectedCompanyname === '') {
        latlongError.selectedCompanyname = "Select Company"
      }
      if (selectedPlantname === '') {
        latlongError.selectedPlantname = "Select Plant"
      }
      if (psgName === '') {
        latlongError.psgName = "Select Ws Rule"
      }
  
     
  
      setLatLongError(latlongError);
  
      return Object.keys(latlongError).length === 0;
  
    }
  
    const addValue= async()=>{
      if(validateAddTime()){
          const add =await AddOfficeTimeService(addTimeUrl, companyCode,plantCode,startTime,endTime,bufferTime,psgId);
         console.log(add);
         if(add.Message==="Updated" || add.Message==="Inserted"){
          setSelectedCompanyName('');
          setCompanyCode('');
          setPlantCode('');
          setSelectedPlantName('');
          setStartTime('');
          setEndTime('');
          setBufferTime('');
          setShowAlert(true);
         }
         else{
          setShowErrorAlert(true);
        }
      }
      
    }
  
    const getCompany = async () => {
      const result = await getCompanyList(companyUrl);
      setCompany(result.items);
  
    }
  
    const getPlant = async (code) => {
  
      const plant = await getPlantList(plantUrl, code);
  
      setPlant(plant.PLANT_DETAILS);
      setIsPlantLoading(false);
     
  
    }

    

    const converStartTime=(date)=>{
      const dateObj = new Date(date);
const options = { hour: 'numeric', minute: 'numeric' };

const formattedTime = new Intl.DateTimeFormat('en-US', options).format(dateObj);
setStartTime(formattedTime);
// console.log(formattedTime);
    }
    const converEndTime=(date)=>{
      const dateObj = new Date(date);
const options = { hour: 'numeric', minute: 'numeric' };

const formattedTime = new Intl.DateTimeFormat('en-US', options).format(dateObj);
setEndTime(formattedTime);
// console.log(formattedTime);
    }
    const converBufferTime=(date)=>{
      const dateObj = new Date(date);
const options = { hour: 'numeric', minute: 'numeric' };

const formattedTime = new Intl.DateTimeFormat('en-US', options).format(dateObj);
setBufferTime(formattedTime);
// console.log(formattedTime);
    }


  return (
    <div className=' w-full flex justify-center items-center'>
      <div className='  mt-24 shadow-xl rounded-lg w-1/2  p-8 flex flex-col items-start '>
         {
              showAlert && <SuccessAlert text={`Time ${astId==null?"Added":"Updated"} Successfully`} setShowAlert={setShowAlert} />
            }
            {
              showErrorAlert && <ErrorAlert  text={`Time ${astId==null?"Adding":"Updating"} Failed`} setShowErrorAlert={setShowErrorAlert}/>
            }

        <h3 className=' text-black text-lg font-semibold my-3'>{astId ==null?"Add Office Time":"Update Office Time"}</h3>
     
        {


          <select placeholder='Select Company' value={companyCode} onChange={(e) => { selectedCompanyCode = e.target.value; setCompanyCode(selectedCompanyCode); setSelectedCompanyName(e.company_name); getPlant(selectedCompanyCode) }} className={`select select-bordered  w-full mb-3 focus:outline-none text-${gray500}  `}>
            <option value='' disabled selected>Select Company</option>
            {
              company.map((e, index) =>
                <option key={index} value={e.company_code}>{e.company_name}</option>
              )
            }
          </select>


        }

        {latlongError.selectedCompanyname && <span className={`text-${red500}`}>{latlongError.selectedCompanyname}</span>}

        {
          isPlantLoading ? <p></p> : <select value={plantCode} onChange={(e) => { selectedPalntCode = e.target.value; setPlantCode(selectedPalntCode); setSelectedPlantName(e.PLANT_NAME) }} className={`select select-bordered w-full mb-3  focus:outline-none text-${gray500}  `}>
            <option value="" disabled selected>Select Plant</option>
            {
              isPlantLoading ?
                <p>loading.....</p>
                : plant.map((e, index) =>
                  <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}</option>
                )
            }

          </select>
        }
        {/* {latlongError.selectedPlantname && <span className={`text-${red500}`}>{latlongError.selectedPlantname}</span>}
        <input ref={lat} onChange={(e) => { setLatitude(e.target.value) }} type="number" placeholder="Latitude" className="input input-bordered w-full focus:outline-none mb-3" />
        {latlongError.latitude && <span className={`text-${red500}`}>{latlongError.latitude}</span>}
        <input ref={long} onChange={(e) => setLongitude(e.target.value)} type="number" placeholder="Longitude" className="input input-bordered w-full focus:outline-none mb-3" />
        {latlongError.longitude && <span className={`text-${red500}`}>{latlongError.longitude}</span>}
        
        <input ref={radi} onChange={(e) => setRaidus(e.target.value)} type="number" placeholder="Radius" className="input input-bordered w-full focus:outline-none mb-3" />
        {latlongError.radius && <span className={`text-${red500}`}>{latlongError.radius}</span>} */}
        <LocalizationProvider
        
        dateAdapter={AdapterDayjs}>
      
        <TimePicker
        className=' w-full   focus:outline-none'
          placeholder="select start Time"
          label="Start Time"
          value={value}
          
          onChange={(newValue) =>{setValue(newValue); converStartTime(newValue)}}
        />

    
    </LocalizationProvider>
    <div className=' mb-4'></div>
    {latlongError.startTime && <span className={`text-${red500}`}>{latlongError.startTime}</span>}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      
        <TimePicker
        className=' w-full '
        
          label="End Time"
          value={value1}
          
          onChange={(newValue) =>{setValue1(newValue); converEndTime(newValue)}}
        />
    
    </LocalizationProvider>
    {latlongError.endTime && <span className={`text-${red500}`}>{latlongError.endTime}</span>}
    <div className=' mb-4'></div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      
        <TimePicker
        className=' w-full'
        
          label="Buffer Time"
          value={value2}
          
          onChange={(newValue) =>{setValue2(newValue); converBufferTime(newValue)}}
        />
    
    </LocalizationProvider>
    {latlongError.bufferTime && <span className={`text-${red500}`}>{latlongError.bufferTime}</span>}
    <div className=' mb-4'></div>
    {
     <select value={psgId} onChange={(e) => { selectPsgid = e.target.value; setPsgId(selectPsgid); setPsgName(e.name); }} className="select select-bordered w-full focus:outline-none mb-2">
     <option value="" disabled selected>Select WS Rule</option>
     {
       psgList.map((e, index) =>
         <option value={e.id} key={index}>{e.name}</option>
       )
     }
   </select>
   
    }
    {latlongError.psgName && <span className={`text-${red500}`}>{latlongError.psgName}</span>}
        <div className=' w-full flex flex-row justify-end'>
          {/* addValue() */}
          <button onClick={()=>{addValue()}} className=' flex w-20 h-10 rounded-3xl justify-center items-center text-white font-semibold text-sm bg-green-600 mb-5'>
            {astId==null?"ADD":"UPDATE"}
          </button>

         
        </div>
      </div>
    </div>
  )
}
