import React,{useEffect, useState} from 'react'
import GeoFenceListService from '../../services/GeoFenceListService';
import Loading from '../loader/Loading';
import { useNavigate } from 'react-router-dom';
const fenceListUrl="http://118.179.60.93:8085/ords/wepl/hrlm/geo_fence_list";

export default function GeoFencePage() {
       const navigate = useNavigate();
    const [fenceList,setFenceList]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
    useEffect(() => {
       
        getFenceList();

    },[] );

    const getFenceList=async()=>{
        const list=await GeoFenceListService(fenceListUrl);
        setFenceList(list.geo_fence_details);
        setIsLoading(false);
    }
 

  const handleClick = (fence) => {
    console.log(fence);
    navigate('/add-fence', { state: { fence } }); // Pass the props as state
  };




  return (
    <div className=' my-16 flex flex-col items-start space-y-20 '>
        <div className='fixed w-full h-16 justify-center items-start    bg-green-200'>
            <button onClick={()=>{navigate('/add-fence')}} className='fixed w-auto p-3 ml-8 mt-2 rounded-md shadow-md text-white font-semibold bg-green-500'>
                ADD NEW FENCE
            </button>
        </div>

{
    isLoading?<Loading/>:
    
    <div className="overflow-x-auto ml-8  mt-36 w-full">
            <div className="inline-block min-w-full">
                <div className=" shadow overflow-hidden">
                    <table className="">
                        <thead className=" bg-gray-200 shadow-sm h-14">
                            <tr>
                            <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">EDIT</th>
                                
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">COMPANY CODE</th>
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">COMPANY NAME</th>
                                {/* Add the rest of the columns */}
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">PLANT CODE</th>
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">PLANT NAME</th>
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">LATITUDE</th>
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">LONGITUDE</th>
                                {/* Add the rest of the columns */}
                                <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">RADIUS</th>
                                
                                
                            </tr>
                        </thead>
                        <tbody className=" divide-y divide-borderColor">
                       { 
                      
                      fenceList.map((fence,index)=>{
                      
                        return(
                            <tr key={index}  className='bg-white'>
                        <td className="px-6 py-4 whitespace-nowrap">
                        <button onClick={()=>{handleClick(fence)}} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
                        </button>

                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.COMPANY_CODE}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.COMPANY_NAME}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.PLANT_CODE}</td>
                                        {/* Render the rest of the columns */}
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.PLANT_NAME}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.LATITUDE}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.LONGITUDE}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{fence.RADIUS}</td>
                                        {/* Render the rest of the columns */}
                                      
                                       
                                    </tr>
                        )
                      })
                                    
                                    }
                               

                        </tbody>
                      
                    </table>
                </div>
            </div>
        </div>
        
        }
  
    </div>
  )
}
